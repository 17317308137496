<template>
  <v-container fluid class="pl-10 pr-10 pb-10">
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Add lines to invoice #{{ invoice.jobNumber }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <p>
          {{ invoice.customer }}
          <span v-if="invoice.customerAddressOne">{{ invoice.customerAddressOne }}, <br /></span>
          <span v-if="invoice.customerAddressTwo">{{ invoice.customerAddressTwo }}, <br /></span>
          <span v-if="invoice.customerAddressThree">{{ invoice.customerAddressThree }}, <br /></span>
          <span v-if="invoice.customerAddressFour">{{ invoice.customerAddressFour }}, <br /></span>
          <span v-if="invoice.customerAddressFive">{{ invoice.customerAddressFive }}, <br /></span>
          <span v-if="invoice.customerPostcode">{{ invoice.customerPostcode }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Add lines to invoice:</p>
      </v-col>
    </v-row>
    <v-row v-for="n in lines" :key="n">
      <v-col cols="12" sm="12" md="7">
        <v-text-field
          v-model="newLines[n - 1].description"
          label="Description"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field
          v-model="newLines[n - 1].quantity"
          label="Quantity"
          type="number"
          min="0"
          step="1"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-text-field
          v-model="newLines[n - 1].value"
          label="Value"
          type="number"
          min="0"
          step="0.01"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addNewLine" small>Add line</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <tbody>
            <tr>
              <th>Sub-total</th>
              <td>{{ newSubTotal | currency }}</td>
              <th>VAT</th>
              <td>{{ newVat | currency }}</td>
              <th>Total</th>
              <td>{{ newTotal | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="saveInvoice" small>Save invoice</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesAddLines',
  data() {
    return {
      lines: 1,
      newLines: [{ description: '', quantity: 0, value: 0 }],
      invoice: {},
      modal: false,
      creditNoteReference: '',
      reasonForCredit: '',
      taxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      newSubTotal: 0,
      newVat: 0,
      newTotal: 0,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    newLines: {
      handler() {
        let subTotal = 0;
        for (let i = 0; this.newLines.length > i; i++) {
          const quantity = this.newLines[i].quantity;
          const amount = this.newLines[i].value;
          subTotal += (quantity * amount);
        }
        this.newSubTotal = subTotal;
        this.newTotal = (this.newSubTotal * 1.2);
        this.newVat = (this.newTotal - this.newSubTotal);
      },
      deep: true,
    },
  },
  methods: {
    saveInvoice() {
      const postData = {};
      postData.quotationId = this.invoice.id;
      postData.lines = this.newLines;
      postData.subtotal = this.newSubTotal;
      postData.vat = this.newVat; 
      axios.post(`/quotations/addInvoiceLines/${this.$route.params.id}.json?token=${this.token}`, postData)
      .then((response) => {
        const invoiceId = response.data.invoice.id;
        this.$router.push(`/invoices/view-draft/${invoiceId}`);
      });
    },
    addNewLine() {
      this.lines += 1;
      const newLine = { description: '', quantity: 0, value: 0 };
      this.newLines.push(newLine);
    },
    getInvoice() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.invoice = response.data;
      });
    },
  },
  mounted() {
    this.getInvoice();
  },
};
</script>